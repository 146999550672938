import { Component, inject } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-thankyou',
  standalone: true,
  templateUrl: './thankyou.component.html'
})
export class ThankyouComponent {

  private dataService: DataService = inject(DataService)

  message: string = this.dataService.message

}
