<div class="client-feedback">
    <div *ngIf="data$ | async" class="container">
        <div class="logo">
            <a href="https://www.easternenterprise.com/">
                <img src="./../../../assets/logo.png" alt="Logo" />
            </a>
        </div>
        <div *ngIf="isFeedbackSubmitted && !questions.length" class="thank-you-page">
            <h3>Please contact csat&#64;easternenterprise.com</h3>
        </div>
        <form novalidate *ngIf="!isFeedbackSubmitted && questions.length" #userForm="ngForm" class="box">
            <div class="csat-form-container">
                <div class="csat-form-content">
                    <div *ngIf="questions.length" class="csat-form-content-header">
                        <span>Dear <strong>{{newFeedback.name}},</strong></span>
                        <p>Please take a moment to provide your feedback on the collaboration with Eastern Enterprise.
                        </p>
                    </div>
                    <div class="divider"></div>
                    <div *ngFor="let question of questions; let qi=index" class="csat-form-content-inner">
                        <div class="csat-question">
                            <div class="csat-question-label" id="question.question_id">{{question.text}} <p
                                    style="color: red; display: inline-block;">*</p>
                            </div>
                            <div *ngIf="question.category=='comment'">
                                <div class="form-check-wrapper" style="display: block;">
                                    <textarea [(ngModel)]="answers[qi].comment" type="text" name="commentTag"
                                        placeholder="Add your remark here..." class="form-control textarea"
                                        required></textarea>
                                    <!-- maxlength="500" (input)="updateCharacterCount($event, qi)"  <p style="display: inline-block; font-size: small; margin: 0;">
                                        {{ 500 - (getCharacterCount(qi) <= 500 ? getCharacterCount(qi) : 500) }}
                                            characters </p> -->
                                </div>
                                <!-- <div class="text-danger" *ngIf="isFormSubmitted && getCharacterCount(qi) > 500">
                                    <span>Maximum 500 characters are allowed</span>
                                </div> -->
                            </div>
                            <div class="d-inline-block" *ngIf="question.category=='rating'">
                                <div class="form-check-wrapper">
                                    <div *ngFor="let number of ratingPoints" class="form-check"
                                        [ngClass]="ratingClass[qi][number]">
                                        <input class="form-check-input" (click)="updateRating(qi, number)"
                                            name="ratingTag" type="radio" value="{{number}}" required />
                                        <span>{{ number }}</span>
                                    </div>
                                </div>
                                <div class="rating-meter-legends"><span>Not Likely</span><span>Very Likely</span></div>
                            </div>
                            <div class="text-danger" *ngIf="!isValidResponse(qi, question) && isFormSubmitted">
                                <span>This is required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="csat-form-buttons">
                        <button class="btn btn-primary" type="button" (click)="onSubmit(userForm)">
                            <img src="./../../../assets/save.svg" alt="save" class="save-img" />
                            Submit</button>
                    </div>
                </div>
            </div>
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" size="medium" type="ball-fussion">
                <p style="color: white"> Please wait... </p>
            </ngx-spinner>
        </form>
    </div>
</div>