import { Routes } from '@angular/router';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { FeedbackComponent } from './components/feedback/feedback.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'feedback/contact',
        pathMatch: 'full'
    },
    {
        path: 'feedback/:token',
        component: FeedbackComponent
    },
    {
        path: 'thank-you',
        component: ThankyouComponent
    },
    {
        path: '**',
        redirectTo: 'feedback/contact',
        pathMatch: 'full'
    }
];
