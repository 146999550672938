import { Feedback, NewFeedback, Question, Answer } from '../../models/feedback';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule, JsonPipe } from '@angular/common';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { FormsModule, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { DataService } from '../../services/data.service';


@Component({
  selector: 'app-feedback',
  standalone: true,
  imports: [FormsModule, CommonModule, JsonPipe, NgxSpinnerComponent],
  templateUrl: './feedback.component.html'
})
export class FeedbackComponent implements OnInit, OnDestroy {

  private spinnerService: NgxSpinnerService = inject(NgxSpinnerService)
  private unsubscribe$: Subject<void> = new Subject<void>();
  private route: ActivatedRoute = inject(ActivatedRoute);
  private http: HttpClient = inject(HttpClient);
  private router: Router = inject(Router);
  private dataService: DataService = inject(DataService);
  private default_token_length: number = 32
  private statusCodes = [422, 400];


  data$: Observable<any> = new Observable();
  validResponses: boolean[] = [];
  isFormSubmitted: boolean = false;
  isFeedbackSubmitted: boolean = true;
  newFeedback: NewFeedback = { name: '', is_submitted: false }
  errorFeedback: NewFeedback = { name: '', is_submitted: false }
  feedback: Partial<Feedback> = { answers: [] }
  questions: Question[] = []
  answers: Partial<Answer>[] = [];
  ratingPoints: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  ratingClass: string[][] = [[], [], [], [], [], [], [], [], [], []];
  success: boolean = false;
  // characterCounts: Map<number, number> = new Map();


  ngOnInit() {
    let token = this.route.snapshot.params['token']
    if (token.length != this.default_token_length) {
      // setTimeout(() => {
      //   window.location.href = 'https://www.easternenterprise.com/';
      // }, 5000);
      this.isFeedbackSubmitted = true
      this.questions = []
      this.data$ = of([])
    } else {
      this.spinnerService.show();
      this.data$ = this.http.get<any>(`${environment.apiUrl}/feedbacks/${token}`, { observe: 'response' })
        .pipe(
          takeUntil(this.unsubscribe$),
          switchMap(response => {
            if (response) {
              this.newFeedback = response.body;
              this.isFeedbackSubmitted = this.newFeedback.is_submitted
              if (response.status === 200) {
                if (this.isFeedbackSubmitted) {
                  this.dataService.message = "Your feedback is already submitted"
                  this.router.navigateByUrl("/thank-you");
                  return of(null)
                }
                this.spinnerService.show();
                return this.http.get<any>(`${environment.apiUrl}/questions/?token=${token}`).pipe(takeUntil(this.unsubscribe$));
              } else if (this.statusCodes.includes(response.status)) {
                if (this.isFeedbackSubmitted) {
                  this.dataService.message = "Your feedback is already submitted"
                  this.router.navigateByUrl("/thank-you");
                }
                this.isFeedbackSubmitted = true
                this.questions = []
                return of(null);
              }
            }
            this.isFeedbackSubmitted = true
            this.questions = []
            return of([]);
          }),
          map(response => {
            this.spinnerService.hide();
            this.questions = response || [];
            for (let q of this.questions) {
              this.answers.push({ question_id: q.question_id })
              this.validResponses.push(false);
              // this.characterCounts.set(i, 0)
            }
            return of([])
          }),
          catchError(error => {
            this.spinnerService.hide();
            this.isFeedbackSubmitted = true
            this.questions = []
            return of([]);
          })
        )
    }
  }

  isValidResponse(qi: number, question: Question): boolean {
    const answer = this.answers[qi];
    const score = answer.score as number;
    const comment = answer.comment as string;

    let isValid = false;

    if (question.category === 'rating' && score != null) {
      isValid = score >= 1 && score <= 10;
    } else if (question.category === 'comment' && comment != null) {
      let _comment = comment.trim()
      isValid = _comment !== '' && _comment.length > 0;
      // && _comment.length <= 500;
    }

    this.validResponses[qi] = isValid;
    return isValid;
  }

  updateRating(qi: number, score: number) {
    this.answers[qi].score = score
    const classToApply = score > 6 && score < 9 ? 'form-check yellow' : score < 7 ? 'form-check red' : 'form-check green'
    this.ratingClass[qi] = []
    for (let i = 0; i <= score; i++) {
      this.ratingClass[qi][i] = classToApply
    }
  }

  getValidClass(qi: number) {
    if (this.answers[qi].score as number < 7) {
      return 'form-check red';
    } else if (this.answers[qi].score as number > 7 || this.answers[qi].score as number < 9) {
      return 'form-check yellow';
    } else if (this.answers[qi].score as number > 8) {
      return 'form-check green';
    }
    return '';
  }

  // updateCharacterCount(event: Event, index: number): void {
  //   const textLength = (event.target as HTMLTextAreaElement).value.length;
  //   this.characterCounts.set(index, textLength);
  // }

  // getCharacterCount(index: number): number {
  //   return this.characterCounts.get(index) != undefined ? this.characterCounts.get(index) as number : 0
  // }

  onSubmit(form: NgForm) {
    this.isFormSubmitted = true;
    if (this.validResponses.includes(false)) {
      return;
    }
    this.spinnerService.show();
    let token = this.route.snapshot.params['token']

    const feedback$ = this.http.get<any>(`${environment.apiUrl}/feedbacks/${token}`, { observe: 'response' })
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(response => {
          this.spinnerService.hide();
          this.newFeedback = response.body;
          this.isFeedbackSubmitted = this.newFeedback.is_submitted;
          if (response.status === 422) {
            if (this.isFeedbackSubmitted) {
              this.dataService.message = "Your feedback is already submitted"
              this.router.navigateByUrl("/thank-you");
            }
            return of(null);
          } else if (response.status === 200) {
            if (!this.isFeedbackSubmitted) {
              this.spinnerService.show();
              this.feedback.answers = this.answers;

              return this.http.post<any>(`${environment.apiUrl}/feedbacks/${token}`, this.feedback, { observe: 'response' })
                .pipe(takeUntil(this.unsubscribe$));
            }
          }
          return of(null);
        }),
        catchError(error => {
          this.spinnerService.hide();
          this.isFeedbackSubmitted = true
          this.questions = []
          return of(null);
        })
      );

    // Subscribe and handle the result
    feedback$.subscribe(response => {
      this.spinnerService.hide();
      if (response) {
        this.newFeedback = response.body;
        if (this.statusCodes.includes(response.status)) {
          this.isFeedbackSubmitted = true
          this.questions = []
          return
        }
        this.dataService.message = "Thank you for your feedback"
        this.router.navigateByUrl("/thank-you");
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}


