<div class="client-info">
    <div class="container">
        <div class="box sucess-box">
            <div class="logo">
                <img src="./../../../assets/logo.png" style="width: 50%; height: 50%;" alt="Logo" />
            </div>
            <img src="./../../../assets/green-checkmark-icon.svg" class="check-mark" alt="Logo" title="Logo" />
            <span class="title-text">{{message}}</span>
            <span class="sub-text">We'll use it to improve our services. You can also send us an email to
                <a href="mailto:csat@easternenterprise.com">csat&#64;easternenterprise.com</a> with additional
                suggestions.</span>
        </div>
    </div>
</div>